import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Table, TableContainer } from "@mui/material";
import React, { useState } from "react";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import MiniTableHeader from "../Common/MiniTableHeader";
import UpdationDetails2 from "../Common/UpdationDetails2";
import ProjectModal from "../Projects/ProjectModal";
import CompanyProjectsTableBody from "./CompanyProjectsTableBody";

const tableData = {
  columns: [
    "Project Name",
    "Project ID",
    "Fiscal Year",
    "SPOC Name",
    "Total Expense",
    "R&D Expense",
    "R&D Potential (%)",
  ],
  rows: [
    {
      id: 1,
      projectName: "Project 1",
      projectId: "000000000011258",
      portfolio: "Rogers",
      accYear: "2022",
      description: "CDU",
      projectManager: "Ezra Romero",
      totalExpense: "$ 15,555.00",
      rndExpense: "$ 5,149.00",
    },
  ],
};

function CompanyProjects({
  data,
  latestUpdateTime,
  modifiedBy,
  comId,
  fetchCompanyProjects,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          {useHasAccessToFeature("F013", "P000000007") && (
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#00A398",
                color: "white",
                mr: 2,
                "&:hover": {
                  backgroundColor: "#00A398",
                },
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              <AddIcon style={{ fontSize: "17px", marginRight: "3px" }} />
              New Project
            </Button>
          )}
          <ProjectModal
            open={modalOpen}
            handleClose={handleModalClose}
            comId={comId}
            fetchProjectData={fetchCompanyProjects}
          />
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            maxHeight: "50vh",
            // scrollbarWidth: "none",
            // msOverflowStyle: "none",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} />
            <CompanyProjectsTableBody filledRows={data} />
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default CompanyProjects;
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputAdornment
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BaseURL } from "../../../constants/Baseurl";
import ProjectTableCell from "../../Common/ProjectTableCell";
import CaseAddProjectModal from "./CaseAddProjectModal";
import { CaseContext } from "../../../context/CaseContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Authorization_header } from "../../../utils/helper/Constant";
// import FlagProjectsModal from "../../Common/FlagProjectsModal";

const styles = {
  flexBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    px: 2,
  },
  label: {
    color: "#404040",
    fontSize: "14px",
    fontWeight: 600,
  },
  inputBase: {
    borderRadius: "20px",
    height: "40px",
    border: "1px solid #E4E4E4",
    pl: 1,
    width: "200px",
  },
  iconStyle: { fontSize: "17px", color: "#00A398" },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    // gap: "0 2rem",
    margin: "auto",
    maxWidth: "100%",
  },
  titleStyle: {
    textAlign: "left",
    fontWeight: 600,
    fontSize: "13px",
  },
  uploadBoxStyle: {
    border: "1px dashed #E4E4E4",
    borderWidth: "2px",
    ml: 2,
    mr: 2,
    borderRadius: "20px",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonStyle: {
    mr: 1,
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#9F9F9F",
    "&:hover": { backgroundColor: "#9F9F9F" },
  },
  uploadButtonStyle: {
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#00A398",
    "&:hover": { backgroundColor: "#00A398" },
  },
  modalStyle: {
    display: "flex",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  buttonBox: {
    mt: 1,
    display: "flex",
    justifyContent: "flex-end",
    px: 2,
    mb: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "left",
    fontSize: "13px",
    py: 1,
  },
  cellCurrencyStyle: {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "right",
    fontSize: "13px",
    py: 1,
  },
  headerRowStyle: {
    backgroundColor: "rgba(64, 64, 64, 0.1)",
  },
  topBoxStyle: {
    borderBottom: "1px solid #E4E4E4",
    px: 2.5,
    textAlign: "left",
    py: 1,
  },
};

const CaseProjectsTab = ({ company, currencySymbol, currency }) => {
  const [search, setSearch] = useState("");
  const [filteredProject, setFilteredProject] = useState([]);
  const [caseProjects, setCaseProjects] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const { detailedCase, getCaseById } = useContext(CaseContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchCompanyProjects, setFetchCompanyProjects] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);

  const handleFlagModalClose = () => {
    setFlagModalOpen(false);
  };

  const handleFlagModalOpen = () => {
    setFlagModalOpen(true);
  }

  const handleSubmenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const fetchAddedCaseProjects = async () => {
    detailedCase?.caseId && getCaseById(detailedCase?.caseId);
    setAnchorEl(null);
    setSelectedIndex(null);
    setLoading(true);
    setCaseProjects([]);
    try {
      if (company) {
        const response = await axios.get(
          `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/${detailedCase?.caseId}/projects`, Authorization_header()
        );
        setCaseProjects(response?.data?.data);
        setLoading(false);
        setFetchCompanyProjects(!fetchCompanyProjects);
      } else {
        console.error("CaseId not available in data object");
        setLoading(false);
        setCaseProjects([]);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setCaseProjects([]);
    }
  };

  const handleRemoveProject = async (projectId) => {
    try {
      const response = await axios.put(
        `${BaseURL}/api/v1/case/${localStorage.getItem(
          "userid"
        )}/projects/${projectId}/remove`, {}, Authorization_header()
      );
      if (response?.data?.success) fetchAddedCaseProjects();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFlagProject = async (projectId, caseFlag) => {
    try {
      const response = await axios.post(
        `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/caseFlag`,
        {
          projectId: projectId,
          caseId: detailedCase?.caseId,
          caseFlag: !caseFlag,
        }, Authorization_header()
      );
      fetchAddedCaseProjects();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setPage(0);
    fetchAddedCaseProjects();
  }, [detailedCase?.caseId]);


  useEffect(() => {
    if (caseProjects) {
      const searchText = search?.trim();
      const filteredData = caseProjects?.filter(
        (task) =>
          task?.projectName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectId + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectCode + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.projectManager?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.totalEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalCosts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDCosts?.toString()?.includes(search?.toLowerCase())
      );
      setFilteredProject(filteredData);
      setPage(0);
    }
  }, [caseProjects, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalClose = () => {
    setOpen(!open);
  };

  const handleModalClose1 = () => {
    setModalOpen(false);
  };
  return (
    <>
      <CaseAddProjectModal
        open={open}
        company={company}
        handleClose={handleModalClose}
        fetchAddedCaseProjects={fetchAddedCaseProjects}
        fetchCompanyProjects={fetchCompanyProjects}
        currencySymbol={currencySymbol}
        currency={currency}
      />
      <Paper
        sx={{ ...styles.paperStyle, ...(!open ? { display: "none" } : {}) }}
      >
        <Box
          sx={{
            px: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0.3rem 0.3rem",
          }}
        >
          <InputLabel sx={styles.label}>Case Projects</InputLabel>
          <InputBase
            type="text"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styles.searchIconStyle} />
              </InputAdornment>
            }
            sx={{
              ...styles.inputBase,
              width: "30%",
              alignItems: "right"
            }}
          />
          <Box sx={{ display: "flex", gap: "2rem" }}>
            <Button
              variant="contained"
              sx={styles.uploadButtonStyle}
              onClick={() => handleModalClose()}
            >
              + Projects
            </Button>
            {/* <Button
            variant="contained"
            sx={styles.uploadButtonStyle}
            onClick={() => handleModalClose()}
          >
            Flag Projects
          </Button> */}
          </Box>
        </Box>
        <Box sx={{ px: 2 }}>
          <TableContainer sx={{ height: 300 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow sx={styles?.headerRowStyle}>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Name
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Id
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Manager
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    SPOC Name
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    SPOC Email
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Total Efforts(Hrs)
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "right",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    {`Total Cost (${currencySymbol} ${currency})`}

                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Total R&D Efforts(Hrs)
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    {`Total R&D Cost (${currencySymbol} ${currency})`}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProject
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row?.projectId + row?.FirstName}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: row?.flag ? "#F4C4AE" : "",
                        }}
                      >
                        <ProjectTableCell
                          id={row?.projectId}
                          name={`${row?.projectName}`}
                        />
                        <ProjectTableCell
                          id={row?.projectId}
                          name={`${row?.projectCode || ""}`}
                        />
                        <TableCell sx={{ ...styles?.cellStyle, textAlign: "left" }}>
                          {(row?.projectManager || "")}
                        </TableCell>
                        <TableCell sx={{ ...styles?.cellStyle, textAlign: "left" }}>
                          {(row?.spocName || "")}
                        </TableCell>
                        <TableCell sx={{ ...styles?.cellStyle, textAlign: "left" }}>
                          {(row?.spocEmail || "")}
                        </TableCell>
                        <TableCell sx={styles?.cellStyle}>
                          {row?.totalefforts ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalefforts) : ""}
                        </TableCell>
                        <TableCell sx={styles?.cellCurrencyStyle}>
                          {row?.totalCosts ? `${currencySymbol}` + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalCosts) : ""}
                        </TableCell>
                        <TableCell sx={styles?.cellStyle}>
                          {row?.totalRnDEfforts ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalRnDEfforts) : ""}
                        </TableCell>
                        <TableCell sx={styles?.cellCurrencyStyle}>
                          {row?.totalRnDcosts ? `${currencySymbol}` + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalRnDcosts) : ""}
                        </TableCell>
                        <TableCell sx={styles?.cellStyle}>
                          <MoreVertIcon
                            sx={{
                              color: "#9F9F9F",
                              cursor: "pointer",
                              transform:
                                selectedIndex !== rowIndex
                                  ? "none"
                                  : "rotate(90deg)",
                            }}
                            onClick={(event) =>
                              handleSubmenuClick(event, rowIndex)
                            }
                          />
                          <Menu
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) && selectedIndex === rowIndex
                            }
                            onClose={handleAnchorClose}
                          >
                            {row?.surveyCode &&
                              <MenuItem onClick={handleAnchorClose}>
                                {row?.surveyCode}
                              </MenuItem>
                            }
                            <MenuItem
                              onClick={() =>
                                handleRemoveProject(row?.caseProjectId)
                              }
                            >
                              Remove Project
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                <CircularProgress sx={{ color: "#00A398" }} />
              </div>
            )}
            {filteredProject.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                No projects found.
              </div>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProject?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        {/* <FlagProjectsModal open={flagModalOpen} handleClose={handleFlagModalClose} /> */}
        <Toaster />
      </Paper>
    </>
  );
};

export default CaseProjectsTab;

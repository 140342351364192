import { CircularProgress, Paper, Table, TableContainer, Box, Drawer } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader from "../../components/Common/TableHeader";
import TableIntro from "../../components/Common/TableIntro";
import ContactModal from "../../components/Contacts/ContactModal";
import ContactsTableBody from "../../components/Contacts/ContactsTableBody";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { BaseURL } from "../../constants/Baseurl";
import { ContactContext } from "../../context/ContactContext";
import { FilterListContext } from "../../context/FiltersListContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { updateTimeDifference } from "../../utils/helper/UpdateTimeDifference";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";
import ContactAddModal from "../../components/Contacts/ContactAddModal";
import EmployeeListing from "../../components/Common/EmployeeListing";
import EmployeeTableBody from "../../components/Common/EmployeeTablebody";
import { EmployeeContext } from "../../context/EmployeeContext";
import { HiFilter } from "react-icons/hi";
import ContactFilters from "../../components/Contacts/ContactFilters";

const tableData = {
  columns: ["Name", "Employee ID", "Employee Role", "Account", "Phone", "Actions"],
  rows: [
    {
      id: 1,
      name: "Adam Smith",
      title: "Finance Head",
      role: "Finance Head",
      company: "Apple Inc.",
      phone: "(336) 222-7000",
      emailAddress: "adam.smith@apple.com",
      rndExpense: "$ 12,213.59",
    },
    // ...add more rows as needed
  ],
};
// const tableData2 = {
//   columns: ["Sheet ID", "Sheet Name", "Related To", "Uploaded By", "Uploaded On", "Status", "Note"],
//   rows: [
//     {
//       sheetID: "SH_0001",
//       sheetName: "Employees Sheet",
//       relatedTo: "Employees",
//       UploadedBy: "Manisha",
//       UploadedOn: "19/08/2024 1:06:04",
//       Status: "Uploaded",
//       Note: "Uploaded successfully"
//     },
//     {
//       sheetID: "SH_0002",
//       sheetName: "infotech_Sheet",
//       relatedTo: "Wages",
//       UploadedBy: "Manisha",
//       UploadedOn: "12/08/2024 1:06:04",
//       Status: "Uploaded",
//       Note: "Uploaded successfully"
//     },
//   ],
// };
const opacityValue = -1;
const styleConstants = {
  filterDownloadStyle: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "28px",
    padding: "5px",
    marginRight: "16px",
    cursor: "pointer",
    // opacity: opacityValue,
  },
  tableContainerStyle: {
    borderLeft: "1px solid #E4E4E4",
    // backgroundColor: `rgba(255, 255, 255, ${opacityValue})`,  // Apply the same opacity to TableContainer
  },
  overlay: {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // width: '100%',
    // height: '100%',
    // backgroundColor: `rgba(0, 0, 0, ${opacityValue})`, // Adjust opacity as needed
    // zIndex: 1100, // Ensure it's above other content but below the drawer/modal
  },
  containerDimmed: {
    // opacity: -10.5,  // Adjust opacity as needed
    // transition: 'opacity 0s ease-in-out',  // Smooth transition
  },
};

function Contacts(page, documentType = "", onApplyFilters) {
  const { pinnedObject } = usePinnedData();
  const { clientList, fetchUserDetails } = useContext(FilterListContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const { fetchEmployeesSheets, employeesSheets } = useContext(EmployeeContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  //000000000000000000000000
  // const employeeNavs = [
  //   { name: "All Employees", isAuth: true },
  //   { name: "Uploaded Sheets", isAuth: true }
  // ];
  const [selectedTab, setSelectedTab] = useState("All Employees");
  const {
    contactData,
    fetchContactData,
    contactFilterState,
    setIsContactFilterApplied,
    setCurrentState,
    currentState,
    loading,
    setContactFilterState,
    triggerContactClearFilters,
  } = useContext(ContactContext);
  const [pinStates, setPinStates] = useState({
    "All Employees": false,
    "Recently Viewed": false,
  });

  const totalPages = Math.ceil(contactData?.length / itemsPerPage);
  const totalPagesForSheets = Math.ceil(employeesSheets?.length / itemsPerPage);

  const appliedFilters = {
    company: contactFilterState.company,
    employeeTitles: contactFilterState.employeeTitles,
    phoned: contactFilterState.phones,
  };

  useEffect(() => {
    setCurrentState(
      pinnedObject?.CONT === "RV" ? "Recently Viewed" : "All Employees"
    );
  }, [localStorage?.getItem("keys")]);

  useEffect(() => {
    const updatedPinStates = {
      "All Employees": pinnedObject.CONT === "ALL",
      "Recently Viewed": pinnedObject.CONT === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.CONT]);

  useEffect(() => {
    const shouldFetchWithFiltersContact =
      contactFilterState.companyIds?.length > 0 ||
      contactFilterState.titleName !== "";
    if (shouldFetchWithFiltersContact) {
      let contactOptions = {
        ...(contactFilterState.companyId?.length > 0 && {
          company: contactFilterState.companyId,
        }),
        ...(contactFilterState.employementType && {
          employementType: [contactFilterState.employementType],
        }),
        ...Authorization_header(contactFilterState.phones !== "" && {
          phones: [contactFilterState.phones],
        })
      };
      fetchContactData(contactOptions);
      // fetchEmployeesSheets(contactOptions);
    } else {
      fetchContactData();
      // fetchEmployeesSheets();
    }
  }, [currentState, Authorization_header]);

  // const applyFiltersAndFetch = (filters) => {
  //   if (areFiltersApplied(appliedFilters)) {
  //     fetchContactData(filters);
  //     fetchEmployeesSheets(filters);
  //     setIsContactFilterApplied(true);
  //   } else {
  //     toast.error("Please select at least one filter.");
  //   }
  // };

  const clearFilters = () => {
    if (page === "contact") {
      setContactFilterState({
        companyId: [],
        company: [],
        employeeTitles: [],
        phones: [],
      });
      onApplyFilters({});
      triggerContactClearFilters();
      setIsContactFilterApplied(false);
    }
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  let currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  let currentSheetData = employeesSheets?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Ensuring currentData always has 20 items
  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }
  const currentPlaceholderRow = {};
  while (currentSheetData?.length < itemsPerPage) {
    currentSheetData.push(placeholderRow);
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };
  const handleEmployeeUploadClick = () => {
    setShowAddEmployeeModal(true);
  }
  const handleEmployeeUploadClose = () => {
    setShowAddEmployeeModal(false);
  }


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const addContact = async (contactInfo) => {
    const apiUrl = `${BaseURL}/api/v1/contacts/${localStorage.getItem(
      "userid"
    )}/1/create-contact`;

    try {
      const response = await axios.post(apiUrl, contactInfo, Authorization_header());
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleAddContact = async (contactInfo) => {
    toast
      .promise(addContact(contactInfo), {
        loading: "Adding New Employee...",
        success: (data) => data?.message || "Employee added successfully",
        error: (error) =>
          error.response?.data?.error?.message || "Failed to add employee.",
      })
      .then(() => {
        fetchContactData();
      })
      .catch((error) => {
        console.error("Employee addition failed:", error);
      });
  };

  const handleSearch = (input) => {
    setSearch(input);

  };

  useEffect(() => {
    if (contactData) {
      const filteredData = contactData?.filter(
        (task) =>
          task?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.lastName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          // task.employeeTitle.toLowerCase().includes(search.toLowerCase()) ||
          task?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.companyIds?.toString()?.includes(search) ||
          task?.companyName?.toString()?.includes(search) ||
          task?.phone?.toString()?.includes(search) ||
          task?.employeeTitle?.toString()?.includes(search)
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [contactData, search]);

  useEffect(() => {
    const timeDifference = updateTimeDifference(contactData, "modifiedTime");
    setLatestUpdateTime(timeDifference);
  }, [contactData]);

  const isCreate = useHasAccessToFeature("F033", "P000000007");
  const isSearch = useHasAccessToFeature("F033", "P000000009");
  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Employees"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Employees"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      CONT: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token_obj.accessToken}` },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);

      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Employees" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  const handleSelectedTab = (name) => {
    setSelectedTab(name);
  }
  useEffect(() => {
    setSelectedTab(selectedTab);
  }, [selectedTab])

  useEffect(() => {

  }, [selectedTab])

  const getSelectedTab = (tabName) => {
    setSelectedTab(tabName);
  }

  useEffect(() => {
    if (selectedTab === 'All Employees') {
      fetchContactData();
    } else if (selectedTab === 'Uploaded Sheets') {
      fetchEmployeesSheets();
    }
  }, [Authorization_header, selectedTab]);



  const handleUploadEmployee = async (values) => {
    const apiUrl = `${BaseURL}/api/v1/contacts/${localStorage.getItem("userid")}/${values.companyId}/employees-upload`;
    const data = {
      companyId: values.companyId,
      employees: values.file,
    };
    toast.loading("Uploading employees sheet....");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        }
      });
      handleEmployeeUploadClose();
      fetchEmployeesSheets();
      toast.dismiss();
      toast.success(response?.data?.message || "employees uploaded successfully");
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload employees sheet.");
    }
  };

  const handleUploadwages = async (values) => {
    const apiUrl = `${BaseURL}/api/v1/contacts/${localStorage.getItem("userid")}/${values.companyId}/wages-upload`;
    const data = {
      companyId: values.companyId,
      wages: values.file,
    };
    toast.loading("Uploading wages sheet....");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        }
      });
      handleEmployeeUploadClose();
      fetchEmployeesSheets();
      toast.dismiss();
      toast.success(response?.data?.message || "wages uploaded successfully");
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload wages sheet.");
    }
  };

  const handleSubmit = (values) => {
    if (values.uploadFor === 'Employee') {
      handleUploadEmployee(values);
    } else if (values.uploadFor === 'Wages') {
      handleUploadwages(values);
    }
  };

  const handleFilterClick = () => {
    setDrawerOpen(!drawerOpen);  // Only manage the drawer's open/close state here
    if (!drawerOpen) {
      setFilterPanelOpen(true);  // Ensure the filter panel is opened when the drawer is opened
    }
  };

  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setDrawerOpen(false);  // Always close the drawer when the filter panel is closed
  };
  const handleFilterClose = () => {
    setFilterPanelOpen(false);
  };


  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchContactData(filters);
      fetchEmployeesSheets(filters);
      setIsContactFilterApplied(true);
    } else {
      toast.error("Please select at least one filter.");
    }
  };
  return (
    <>
      <Box
        sx={{
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {useHasAccessToFeature("F033", "P000000008") && (
          <Paper
            sx={{
              display: "flex",
              width: "98.5%",
              mx: "auto",
              mt: 1,
              flexDirection: "column",
              borderRadius: "20px",
              mb: 3,
              boxShadow: "0px 3px 6px #0000001F",
            }}
          >
            <TableIntro
              heading={
                pinnedObject?.CONT === "RV" ? "Recently Viewed" : "All Employees"
              }
              btnName={"New Employee"}
              btnNameX={"Upload"}
              page={"contact"}
              data={contactData}
              totalItems={filteredRows?.length || 0}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onUploadClick={handleUploadClick}
              onUploadClickX={handleEmployeeUploadClick}
              onSearch={handleSearch}
              latestUpdateTime={latestUpdateTime}
              items={["All Employees", "Recently Viewed"]}
              onApplyFilters={applyFiltersAndFetch}
              appliedFilters={appliedFilters}
              createPermission={isCreate}
              searchPermission={isSearch}
              onSelectedItem={handleSelectedHeaderItem}
              isPinnedState={pinStates[currentState]}
              onPinClicked={() => togglePinState(currentState)}
              handleSelectedTab={handleSelectedTab}
              selectedTab={selectedTab}
            />
            <ContactAddModal
              open={showAddEmployeeModal}
              handleClose={handleEmployeeUploadClose}
              handleSubmit={handleSubmit}
              type={"upload"}
            />
            <ContactModal
              open={modalOpen}
              handleClose={handleModalClose}
              onAddContact={handleAddContact}
              clients={clientList}
            />
            <Box sx={{ display: "flex", pt: 0, pb: page === "activity" ? -1 : 0 }}>
              <Box sx={{ marginLeft: "7px", marginTop: "-2px", display: "flex", alignItems: "center" }}>
                {!(page === "alerts") && (
                  <HiFilter
                    style={{
                      ...styleConstants.filterDownloadStyle,
                      marginTop: "-80px",
                      zIndex: 2,
                      marginRight: "-10px"
                    }}
                    onClick={handleFilterClick}
                  />
                )}
              </Box>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleFilterPanelClose}
                sx={{
                  width: '300px',
                  flexShrink: 0,
                }}
                variant="persistent"
              >
                {filterPanelOpen && (
                  <ContactFilters
                    handleClose={handleFilterPanelClose}
                    open={filterPanelOpen}
                    page={page}
                    documentType={documentType}
                    // onApplyFilters={onApplyFilters}
                    onApplyFilters={applyFiltersAndFetch}
                    style={{ position: 'absolute', left: 0 }}
                  />
                )}
              </Drawer>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: drawerOpen ? '300px' : '0',
              }}
            >
              {selectedTab === "All Employees" && (
                <TableContainer
                  sx={{
                    maxHeight: "82vh",
                    overflowY: "auto",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHeader tableData={tableData} page="contacts" />
                    {!loading && (
                      <ContactsTableBody
                        data={currentData}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                      />
                    )}
                  </Table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        minHeight: "380px",
                      }}
                    >
                      <CircularProgress sx={{ color: "#00A398" }} />
                    </div>
                  )}
                  {currentData?.length === 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        minHeight: "380px",
                      }}
                    >
                      No Employees found.
                    </div>
                  )}
                </TableContainer>
              )}
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={handleChangePage}
                changeItemsPerPage={handleChangeItemsPerPage}
                minRows={20}
              />
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
}

export default Contacts;

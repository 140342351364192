import { Box, Paper } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../../components/Common/BarChart";
import ProjectsTableStack from "../../components/Projects/ProjectsTableStack";
import { BaseURL } from "../../constants/Baseurl";
import { ProjectContext } from "../../context/ProjectContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import toast, { Toaster } from "react-hot-toast";
import {
  DiffBwDays,
  createObjectFromArray,
} from "../../utils/helper/DiffBwDays";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import {
  getMonthYear,
  getTimeDifference,
} from "../../utils/helper/UpdateTimeDifference";
import { Authorization_header } from "../../utils/helper/Constant";
import { SheetsContext, projectsSheets } from "../../context/SheetsContext";

const chartPaperStyle = {
  p: 1,
  flex: 1,
  borderRadius: "20px",
  height: "300px",
  boxShadow: "0px 3px 6px #0000001F",
};

const layoutBoxStyle = {
  width: "98%",
  mx: "auto",
  display: "flex",
  mt: 2,
  gap: "20px",
};

function Projects() {
  const { projects, fetchProjects, projectFilterState, setIsProjectFilterApplied, currentState,
    setCurrentState,
    loading } = useContext(ProjectContext);
  const [projectRnD, setProjectRnd] = useState(null);
  const [projectTtc, setProjectTtc] = useState(null);
  const [projectUnc, setProjectUnc] = useState(null);
  const [rndPercentages, setRndPercentages] = useState(null);
  const [ttc, setTtc] = useState(null);
  const [uncertainHours, setUncertainHours] = useState(null);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [labelObj, setLabelObj] = useState(null);
  const { fetchProjectsSheets, projectsSheets } = useContext(SheetsContext);
  const [selectedTab, setSelectedTab] = useState("");
  // const [sheets, setSheets] = useState("");

  // let projectsOptions;
  // useEffect(() => {
  //   const shouldFetchWithFiltersProjects =
  //     projectFilterState.companyId?.length > 0 ||
  //     projectFilterState.portfolioId?.length > 0 ||
  //     projectFilterState.accountingYear?.length > 0 ||
  //     projectFilterState.totalExpense?.length > 0 ||
  //     projectFilterState.rndExpense?.length > 0 ||
  //     projectFilterState.rndPotential?.length > 0;

  //   if (shouldFetchWithFiltersProjects) {
  //     projectsOptions = {
  //       ...(projectFilterState.companyId?.length > 0 && {
  //         companyId: projectFilterState.companyId,
  //       }),
  //       ...(projectFilterState.portfolioId?.length > 0 && {
  //         portfolioId: projectFilterState.portfolioId,
  //       }),
  //       ...(projectFilterState.accountingYear?.length > 0 && {
  //         accountingYear: projectFilterState.accountingYear,
  //       }),
  //       ...(projectFilterState.totalExpense && {
  //         minTotalExpense: projectFilterState.totalExpense[0],
  //       }),
  //       ...(projectFilterState.totalExpense && {
  //         maxTotalExpense: projectFilterState.totalExpense[1],
  //       }),
  //       ...(projectFilterState.rndExpense && {
  //         minRnDExpense: projectFilterState.rndExpense[0],
  //       }),
  //       ...(projectFilterState.rndExpense && {
  //         maxRnDExpense: projectFilterState.rndExpense[1],
  //       }),
  //       ...(projectFilterState.rndPotential && {
  //         minRnDPotential: projectFilterState.rndPotential[0],
  //       }),
  //       ...(projectFilterState.rndPotential && {
  //         maxRnDPotential: projectFilterState.rndPotential[1],
  //       }),
  //     };
  //     fetchProjects(projectsOptions);
  //   }
  //   else {
  //     fetchProjects();
  //   }
  // }, [projectFilterState, currentState, Authorization_header]);

  const fetchProjectKPIData = async () => {
    try {
      const response1 = await axios.get(
        `${BaseURL}/api/v1/projects/${localStorage.getItem(
          "userid"
        )}/1/projects-kpi`, Authorization_header()
      );
      setProjectRnd(response1.data.data?.rndPercent?.projectName);
      setRndPercentages(response1.data.data?.rndPercent?.rndPercentage);
      setProjectTtc(response1.data.data?.ttc?.projectName);
      setTtc(response1.data.data?.ttc?.ttc);
      setProjectUnc(response1.data.data?.uncertainHours?.projectName);
      setUncertainHours(response1.data.data?.uncertainHours?.uncertainHours);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProjectKPIData();
    setCurrentState(
      // (pinnedObject && pinnedObject?.CLNT === "RV" ? "Recently Viewed" : "All Accounts")
      (pinnedObject) => {
        if (pinnedObject && pinnedObject?.CLNT && pinnedObject?.CLNT === "RV") {
          return "Recently Viewed";
        } else {
          return "All Projects";
        }
      }
    );
  }, [Authorization_header]);

  const appliedFilters = {
    Clients: projectFilterState.company,
    Projects: projectFilterState.project,
    AccountingYear: projectFilterState.accYear,
    SpocName: projectFilterState.spocName,
    SpocEmail: projectFilterState.SpocEmail,
    MinTotalExpense: projectFilterState.totalExpense ? projectFilterState.totalExpense[0] : null,
    MaxTotalExpense: projectFilterState.totalExpense ? projectFilterState.totalExpense[1] : null,
    MinRnDExpense: projectFilterState.rndExpense ? projectFilterState.rndExpense[0] : null,
    MaxRnDExpense: projectFilterState.rndExpense ? projectFilterState.rndExpense[1] : null,
    MinRnDPotential: projectFilterState.rndPotential ? projectFilterState.rndPotential[0] : null,
    MaxRnDPotential: projectFilterState.rndPotential ? projectFilterState.rndPotential[1] : null,
  };

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchProjects(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };


  const getSelectedTab = (tabName) => {
    setSelectedTab(tabName);
  }

  useEffect(() => {
    if (selectedTab === "Uploaded Sheets") {
      fetchProjectsSheets();
    } else if (selectedTab === "All Projects") {
      fetchProjects();
    }
  }, [Authorization_header, selectedTab])

  useEffect(() => {
    fetchProjectKPIData();
  }, [Authorization_header]);

  useEffect(() => {
    const timeDifference = getTimeDifference(projects, "modifiedTime");
    setLatestUpdateTime(timeDifference);
  }, [projects, Authorization_header]);

  useEffect(() => {
    setLabelObj(
      createObjectFromArray(
        DiffBwDays(ttc),
        ttc?.map((item) => getMonthYear(item))
      )
    );
  }, [ttc, Authorization_header]);

  const customLabel1 = {
    rotation: 0,
    format: "{text}%",
    overflow: "justify",
  };
  const customLabel2 = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };

  const customLabel3 = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };

  return (
    <>
      {useHasAccessToFeature("F013", "P000000008") && (
        <Paper
          sx={{
            width: "98.5%",
            mx: "auto",
            mt: 1,
            borderRadius: "25px",
            mb: 3,
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <ProjectsTableStack
            loading={loading}
            latestUpdateTime={latestUpdateTime?.difference}
            getSelectedTab={getSelectedTab}
            projectsSheets={projectsSheets}
            onApplyFilters={applyFiltersAndFetch}
            appliedFilters={appliedFilters}
          />
        </Paper>
      )}
    </>
  );
}

export default Projects;

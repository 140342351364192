import { Box, Paper, TableContainer, Table, CircularProgress, TablePagination } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import InfoboxHeader from "../../components/Common/InfoboxHeader";
import MainPanelHeader from "../../components/Common/MainPanelHeader";
import SearchboxBody from "../../components/Common/SearchboxBody";
import SearchboxHeader from "../../components/Common/SearchboxHeader";
import Details from "../../components/Projects/Details";
import Financial from "../../components/Projects/Financial";
import ProjectsInfoboxTable from "../../components/Projects/ProjectsInfoboxTable";
import Team from "../../components/Projects/Team";
import Task from "../../components/Projects/Task"
import Timesheet from "../../components/Projects/Timesheet";
import { BaseURL } from "../../constants/Baseurl";
import { NotificationContext } from "../../context/NotificationContext";
import {
  getTimeDifference,
  updateTimeDiff,
} from "../../utils/helper/UpdateTimeDifference";
import toast, { Toaster } from "react-hot-toast";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import Documents from "../../components/Projects/Documents";
import { DocumentContext } from "../../context/DocumentContext";
import { FilterListContext } from "../../context/FiltersListContext";
import { ProjectContext } from "../../context/ProjectContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import TechnicalSummary from "../../components/Cases/TechnicalSummaryTab/TechnicalSummary";
import Interaction from "../../components/Cases/IneractionTab/Interaction";
import { useLocation } from "react-router-dom";
import { Authorization_header } from "../../utils/helper/Constant";

const fieldMapping = {
  Field0: "companyName",
  Field2: "projectManager",
  Field3: "projectName",
};

const tableData = {
  columns: [
    "Task Date",
    "Project ID",
    "Task Description",
    "Employee",
    "R&D Classification",
    "Hourly Rate",
    "Task Effort(Hrs)",
    "Total Expense",
    "R&D Expense",
    "Created By",
    "Created Time",
    "Modified By",
    "Modified Time",
    "Task ID",
  ],
  rows: [
    {
      id: 1,
      taskDate: "12/10/2023",
      taskID: "43568",
      taskDesc: "Task Description",
      employee: "Ezra Romero",
      rndClassification: "R&D",
      hourlyRate: "$ 70.00",
      taskEffort: "8.00",
      totalExpense: "$ 560.00",
      rndExpense: "$ 560.00",
    },
  ],
};
function ProjectsInfo() {
  const arr = [
    { name: "Financial", isAuth: useHasAccessToFeature("F014", "P000000003") },
    { name: "Details", isAuth: useHasAccessToFeature("F015", "P000000003") },
    { name: "Team", isAuth: useHasAccessToFeature("F017", "P000000003") },
    { name: "Timesheets", isAuth: useHasAccessToFeature("F018", "P000000003") },
    { name: "Task", isAuth: useHasAccessToFeature("F024", "P000000003") },
    { name: "Technical Summary", isAuth: useHasAccessToFeature("F022", "P000000003") },
    { name: "Interactions", isAuth: useHasAccessToFeature("F023", "P000000003") },
    { name: "Documents", isAuth: useHasAccessToFeature("F021", "P000000003") },
  ];
  const { pinnedObject } = usePinnedData();
  const {
    projects,
    fetchProjects,
    projectFilterState,
    setCurrentState,
    currentState,
  } = useContext(ProjectContext);
  const [selectedTab, setSelectedTab] = useState("Financial");
  const [selectedProject, setSelectedProject] = useState(null);
  const [teamData, setTeamData] = useState(null);

  const [timesheetData, setTimesheetData] = useState(null);
  // const [summaryDetails, setSummaryDetails] = useState(null);
  const [details, setDetails] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [totalBudget, setTotalBudget] = useState([0]);
  const [totalExpense, setTotalExpense] = useState([0]);
  const [rndExpense, setRndExpense] = useState([0]);
  const [financialData, setFinancialData] = useState(null);
  const [date, setDate] = useState(null);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [latestTimesheetUpdateTime, setLatestTimesheetUpdateTime] = useState("Just now");
  const [latestTeamUpdateTime, setLatestTeamUpdateTime] = useState("Just now");
  const [latestDetailUpdateTime, setDetailLatestUpdateTime] = useState("Just now");
  const [latestDocumentUpdateTime, setDocumentLatestUpdateTime] = useState("Just now");
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const { documents, fetchDocuments } = useContext(DocumentContext);
  const { fetchUserDetails } = useContext(FilterListContext);
  const [projectId, setProjectId] = useState("");
  const [companyId, setCompanyId] = useState();
  const [pinStates, setPinStates] = useState({
    "All Projects": false,
    "Recently Viewed": false,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // queryParams.get('verifycode');
  const ProjectId = queryParams.get('projectId');
  const SelectedTab = queryParams.get('tabName');
  useEffect(() => {
    setProjectId(ProjectId);
    setSelectedTab(SelectedTab || "Financial");
  }, [localStorage?.getItem("keys")])


  const handleSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleSelectedItem = (selectedItemData) => {
    setSelectedProject(selectedItemData);
    setProjectId(selectedItemData?.projectId);
    setCompanyId(selectedItemData?.companyId);
  };

  const handleDocumentUploadSuccess = () => {
    setShouldRefetch(true);
  };


  // const handleChangePage = (newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setItemsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  useEffect(() => {
    fetchDocuments({
      companyIds: [companyId?.toString()],
      relatedTo: "projects",
      relationId: projectId?.toString(),
    });
    setShouldRefetch(false);
  }, [projectId, shouldRefetch]);

  const fetchData = async () => {
    try {


      if (projectId && companyId) {
        const results = await Promise.allSettled([
          axios.get(
            `${BaseURL}/api/v1/projects/${localStorage.getItem(
              "userid"
            )}/${companyId}/${projectId}/get-team-members`, Authorization_header()
          ),
          axios.get(
            `${BaseURL}/api/v1/projects/${localStorage.getItem(
              "userid"
            )}/${companyId}/${projectId}/get-project-financials`, Authorization_header()
          ),
          axios.get(
            `${BaseURL}/api/v1/projects/${localStorage.getItem(
              "userid"
            )}/${companyId}/${projectId}/project-details`, Authorization_header()
          ),
          axios.get(
            `${BaseURL}/api/v1/projects/${localStorage.getItem(
              "userid"
            )}/${companyId}/${projectId}/get-timesheets-by-project`, Authorization_header()
          ),
        ]);

        const [response1, response2, response3, response4, response5] = results?.map(
          (result) => (result?.status === "fulfilled" ? result?.value : null)
        );
        setTeamData(response1?.data?.data);
        setFinancialData(response2?.data?.data);
        const totalExpenseNumbers = response2?.data?.data?.TotalExpense?.map(expense => parseInt(expense, 10)) || [0];
        setTotalExpense(totalExpenseNumbers);
        const projectRnDExpenseNumbers = response2?.data?.data?.projectRnDExpense?.map(expense => parseInt(expense, 10)) || [0];
        setRndExpense(projectRnDExpenseNumbers);
        setDate(response2?.data?.data?.projectDate);
        setDetails(response3?.data?.data);
        setTimesheetData(response4?.data?.data);
      } else {
        console.error("projectId not available in data object");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [projectId, companyId]);

  const handleSearch = (input) => {
    setSearch(input);

  };



  useEffect(() => {
    if (projects) {
      const filteredData = projects?.filter(
        (task) =>
          // task.projectManager.toLowerCase().includes(search.toLowerCase()) ||
          task?.projectName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.companyId?.toString()?.includes(search)
      );
      setFilteredRows(filteredData);
    }
  }, [projects, search]);

  useEffect(() => {
    const timeDifference = getTimeDifference(projects, "modifiedTime");
    setLatestUpdateTime(timeDifference);
    // const timeDifference1 = getTimeDifference(financialData, "modifiedTime");
    // setLatestFinancialUpdateTime(timeDifference1);
    const timeDifference2 = updateTimeDiff(details);
    setDetailLatestUpdateTime(timeDifference2);

    const timeDifference3 = getTimeDifference(teamData, "modifiedTime");
    setLatestTeamUpdateTime(timeDifference3);
    const timeDifference4 = getTimeDifference(timesheetData, "modifiedTime");
    const timeDifference5 = getTimeDifference(documents, "modifiedTime");
    setLatestTimesheetUpdateTime(timeDifference4);
    setDocumentLatestUpdateTime(timeDifference5);
  }, [projects, details, financialData, teamData, timesheetData]);
  const { updateAlertCriteria } = useContext(NotificationContext);

  useEffect(() => {
    const pageName = "project";
    const relationId = projectId;

    updateAlertCriteria(pageName, relationId);

    return () => updateAlertCriteria(null, null);
  }, [projectId]);

  const appliedFilters = {
    Clients: projectFilterState.company,
    Projects: projectFilterState.project,
    AccountingYear: projectFilterState.accYear,
  };

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchProjects(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };

  useEffect(() => {
    setCurrentState(
      pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
    );
  }, [localStorage?.getItem("keys")]);

  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState?.companyId?.length > 0 ||
      projectFilterState?.portfolioId?.length > 0 ||
      projectFilterState?.accountingYear?.length > 0;
    if (shouldFetchWithFiltersProjects) {
      let options = {
        ...(projectFilterState?.companyId?.length > 0 && {
          companyId: projectFilterState?.companyId,
        }),
        ...(projectFilterState?.portfolioId?.length > 0 && {
          portfolioId: projectFilterState?.portfolioId,
        }),
        ...(projectFilterState?.accountingYear?.length > 0 && {
          accountingYear: projectFilterState?.accountingYear,
        }),
      };
      fetchProjects(options);
    } else {
      fetchProjects();
    }
  }, [currentState]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const isDownload = useHasAccessToFeature("F013", "P000000006");

  useEffect(() => {
    const updatedPinStates = {
      "All Projects": pinnedObject.PROJ === "ALL",
      "Recently Viewed": pinnedObject.PROJ === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.PROJ]);

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Projects"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Projects"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      PROJ: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);

      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Projects" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  const codePoint = parseInt(details?.overview[0]?.currencySymbol, 16);
  const symbol = String.fromCharCode(codePoint);

  return (
    <>

      <Box
        sx={{ display: "flex", width: "98%", mx: "auto", gap: "20px", mt: 3 }}
      >
        <Paper
          sx={{
            display: "flex",
            // flex: 1,
            width: "23%",
            borderRadius: "20px",
            flexDirection: "column",
            height: "100vh",
            mb: 3,
            overflowY: "hidden",
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <SearchboxHeader
            type={
              pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
            }
            onSearch={handleSearch}
            data={filteredRows}
            latestUpdateTime={latestUpdateTime?.difference}
            items={["All Projects", "Recently Viewed"]}
            page="project"
            onApplyFilters={applyFiltersAndFetch}
            searchPermission={useHasAccessToFeature("F013", "P000000009")}
            onSelectedItem={handleSelectedHeaderItem}
            isPinnedState={pinStates[currentState]}
            onPinClicked={() => togglePinState(currentState)}
          />
          <Box sx={{ overflowY: "auto" }}>
            <SearchboxBody
              data={filteredRows}
              fieldMapping={fieldMapping}
              onItemSelected={handleSelectedItem}
              page={"project"}
            />
          </Box>
        </Paper>
        <Box sx={{ width: "77%", display: "flex", flexDirection: "column" }}>
          <Paper
            sx={{
              borderRadius: "20px",
              mb: 3,
              boxShadow: "0px 3px 6px #0000001F",
            }}
          >
            <InfoboxHeader
              head={selectedProject?.projectName}
              projectId={selectedProject?.projectId}
              page={"projects"}
              downloadPermission={isDownload}
            />
            <ProjectsInfoboxTable
              info={details?.overview[0]}
              onSelectedChange={handleSelectedTab}
              symbol={symbol}
            />
          </Paper>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Paper
              sx={{
                borderRadius: "20px",
                width: "100%",
                boxShadow: "0px 3px 6px #0000001F",
              }}
            >
              <MainPanelHeader
                arr={arr}
                first={arr?.[0]?.name}
                onSelectedChange={handleSelectedTab}
                selectedTab={selectedTab}
              />
              {useHasAccessToFeature("F014", "P000000003") &&
                selectedTab === "Financial" && (
                  <Financial
                    symbol={symbol}
                    currency={details?.overview[0]?.currency}
                    data={details?.overview}
                    info={details?.overview[0]}
                    totalBudget={totalBudget}
                    totalExpense={totalExpense}
                    rndExpense={rndExpense}
                    date={date}
                    latestUpdateTime={latestDetailUpdateTime}
                    modifiedBy={details?.modifiedBy}
                  />
                )}
              {useHasAccessToFeature("F015", "P000000003") &&
                selectedTab === "Details" && (
                  <Details
                    data={details?.overview[0]}
                    latestUpdateTime={latestDetailUpdateTime}
                    modifiedBy={details?.modifiedBy}
                    fetchData={fetchData}
                    milestones={details?.milestones}
                  />
                )}
              {useHasAccessToFeature("F017", "P000000003") &&
                selectedTab === "Team" && (
                  <Team
                    symbol={symbol}
                    data={teamData}
                    latestUpdateTime={latestTeamUpdateTime?.difference}
                    modifiedBy={latestTeamUpdateTime?.modifiedBy}
                    fetchTeamMembers={fetchData}
                    details={details?.overview[0]}
                  />
                )}
              {useHasAccessToFeature("F018", "P000000003") &&
                selectedTab === "Timesheets" && (
                  <Timesheet
                    data={timesheetData}
                    latestUpdateTime={latestTimesheetUpdateTime?.difference}
                    modifiedBy={latestTimesheetUpdateTime?.modifiedBy}
                  />
                )}
              {useHasAccessToFeature("F017", "P000000003") &&
                selectedTab === "Task" && (
                  <Task
                    details={details?.overview[0]}
                    symbol={symbol}
                  />
                )}
              {useHasAccessToFeature("F018", "P000000003") &&
                selectedTab === "Technical Summary" && (
                  <TechnicalSummary usedfor={'project'} projectId={details?.overview[0]?.id} />
                )}
              {useHasAccessToFeature("F022", "P000000003") &&
                selectedTab === "Interactions" && (
                  <Interaction usedfor={'project'} projectId={details?.overview[0]?.id} />

                )}

              {useHasAccessToFeature("F021", "P000000003") &&
                selectedTab === "Documents" && (
                  <Documents
                    data={documents}
                    onProjectDocumentUploadSuccess={handleDocumentUploadSuccess}
                    page="project"
                    comId={companyId}
                    projId={projectId}
                    projName={selectedProject?.projectName}
                    comName={selectedProject?.companyName}
                    latestUpdateTime={latestDocumentUpdateTime?.difference}
                    modifiedBy={latestDocumentUpdateTime?.modifiedBy}
                  />
                )}

            </Paper>
          </Box>
        </Box>
        <Toaster />
      </Box>
    </>
  );
}

export default ProjectsInfo;
